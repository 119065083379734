(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo.authentication.login.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.authentication.login')
  .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl($state, $filter, $mdToast, AuthenticationService) {
    var vm = this;
    vm.ctrlName = 'LoginCtrl';

    vm.email = undefined;
    vm.password = undefined;
    vm.login = login;

    function login() {
      AuthenticationService.login(vm.email, vm.password)
        .then(function () {
          $state.go('home');
        })
        .catch(function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('EMAIL_PASSWORD_INVALID'))
              .position('bottom left')
              .hideDelay(5000));
        });
    }
  }
}());
